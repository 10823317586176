

/* Extra small devices (phones, 576px and down) */
@media (max-width: 576px) {
    .image_section {
        flex-wrap: wrap;
    }
}

/* Small devices (tablets, 768px and down) */
@media (max-width: 768px) {
    
}

/* Medium devices (desktops, 992px and down) */
@media (max-width: 992px) {
    
}

/* Large devices (large desktops, 1200px and down) */
@media (max-width: 1200px) {
    
}

/* Extra large devices (extra large desktops, 1400px and down) */
@media (max-width: 1400px) {
    
}
