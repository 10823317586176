@import "../Font/font.scss";
@import "./Variables.scss";
@import "./_input.scss";
@import "./responsive.scss";
@import "../Components/Patients/Patients.scss";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}

body {
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
}

.cursor-pointer {
  cursor: pointer;
}

.mt-6 {
  margin-top: 4.5rem;
}

.back_btn button {
  position: fixed;
  top: 30px;
  left: 30px;
  min-width: auto !important;
}

.custom_table {
  min-height: 57vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-check-input:checked,
.form-check-input[type=checkbox]:indeterminate {
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: none;
}

.form-check-input:focus {
  box-shadow: none;
}

table {
  tbody {
    border-bottom-width: 0 !important;
    text-wrap: nowrap;

    tr {
      cursor: pointer;

      td {
        vertical-align: middle;
        font-size: var(--p);
        font-weight: 600;
        padding: 0.6rem 0.5rem;
        border-bottom-width: 0 !important;
        color: var(--black) !important;
        height: 40px;

        small {
          font-weight: 400;
        }

        &:first-child {
          text-align: left;
          padding-left: 1.5rem;
          height: 40px;
        }

        &:last-child {
          padding-right: 2.5rem;
        }
      }
    }
  }

  thead {
    border-bottom-width: 0 !important;

    tr {
      th {
        background-color: var(--outlet-bg) !important;
        text-wrap: nowrap;
        border-bottom-width: 0 !important;
        color: var(--light-text) !important;
        font-size: var(--p0);
        padding: 0.8rem 0.5rem !important;

        &:last-child {
          padding-right: 2.5rem !important;
        }
      }
    }
  }
}

.accordion-button {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.lbl-mb {
  label {
    margin-bottom: 0.5rem !important;
  }

  textarea {
    padding: 0;
  }
}

.small-table {
  table {
    thead {
      th {
        padding: 1rem 0.5rem !important;
        font-weight: 500;
      }
    }

    tbody {
      border-bottom-width: 0 !important;
      text-wrap: nowrap;

      tr {
        cursor: pointer;

        td {
          vertical-align: middle;
          font-size: var(--p);
          font-weight: 600;
          padding: 0.6rem 0.5rem;
          border-bottom-width: 0 !important;
          color: var(--black) !important;

          small {
            font-weight: 400;
          }

          &:first-child {
            text-align: left;
            padding-left: 1.5rem;
            height: 40px;
          }

          &:last-child {
            padding-right: 2.5rem;
          }
        }
      }
    }

    thead {
      border-bottom-width: 0 !important;

      tr {
        th {
          background-color: var(--outlet-bg) !important;
          text-wrap: nowrap;
          border-bottom-width: 0 !important;
          color: var(--light-text) !important;
          font-size: var(--p0);
          padding: 0.8rem 0.5rem !important;

          &:last-child {
            padding-right: 2.5rem !important;
          }
        }
      }
    }
  }
}

.date-picker {
  row-gap: 1rem;

  label {
    margin-bottom: 0.5rem;
    color: var(--light-text);
    font-size: var(--p);
    font-weight: 400;
    line-height: 21px;
  }

  .input-group-text {
    padding-left: 0;
  }

  .react-datepicker-popper {
    z-index: 9;
    line-height: 0;
  }

  .input-group {
    flex-wrap: nowrap;

    .react-datepicker-wrapper {
      width: 100%;

      input {
        border: none;
        padding: 0.575rem 0;
        font-size: var(--p);
        font-weight: 700;
        color: var(--light-text);
        min-width: 100px;
        width: 100%;
        border-bottom: 1px solid var(--border-light);

        &::placeholder {
          color: var(--placeholder);
          font-size: var(--p);
        }

        &:focus {
          box-shadow: none;
          outline: none;
        }

      }
    }
  }
}

.amount {
  max-width: 82px;
  text-align: right;
}

.hand-hover {
  cursor: pointer;
}

.dropdown {
  padding: 0;
  border: none !important;

  &:focus {
    outline: none;
    border: 0;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .dropdown-toggle {
    font-weight: 700;
    color: var(--light-text);
    padding: 0.5rem 0 !important;
    background-color: transparent !important;
    border-color: transparent !important;

    &:hover {
      color: var(--black4);
    }

    &::after {
      display: none;
    }
  }
}

.status {
  padding: 0.4rem 0.5rem;
  border-radius: 8px;
  width: fit-content;
  margin: 0 auto;

  p {
    text-transform: capitalize;
  }

  &__primary {
    background-color: var(--list-hover);
    color: var(--counselor);
  }

  &__secondary {
    background-color: var(--light-yellow);
    color: var(--yellow);
  }

  &__success {
    background-color: var(--success-bg);
    color: var(--success);
  }

  &__pending {
    background-color: var(--success-bg);
  }

  &__reserved {
    background-color: var(--placeholder);
  }

  &__occupied {
    background-color: var(--yellow);
    color: var(--white);
  }

  &__fail {
    background-color: var(--fail-bg);
    color: var(--fail);
  }

  &__name {
    background-color: var(--highlight-name-bg);
    color: var(--highlight-name-text);
  }
}


/* common */
.ribbon {
  width: 111px;
  height: 111px;
  overflow: hidden;
  position: absolute;
}



.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0;
  background-color: var(--primary);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Plus Jakarta Sans", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  z-index: 10000;
  position: fixed;
  bottom: -18px;
  right: -23px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  right: -58px;
  bottom: 25px;
  transform: rotate(-45deg);
}


@media (max-width: 800px) {
  .modal-dialog {
    max-width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 468px) {
  .Toastify__toast-container {
    width: 80vw;
    top: 2% !important;
    left: 10%;
  }

  .date-picker .input-group .react-datepicker-wrapper input {
    min-width: auto !important;
  }

  .home .main_outlet .nav_bar {
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.round-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: var(--primary);
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: 0;
  color: white;
  font-weight: bold;
}

.nav-tabs {
  .nav-link {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-inline: 2rem;
    color: var(--primary);
    background-color: var(--outlet-bg);

    &.active {
      color: var(--white);
      background-color: var(--primary);
    }
  }
}

.required {
  position: relative;

  &::after {
    content: "*";
    color: var(--fail);
    display: inline;
    margin-left: 0.3rem;
  }
}

.chips-container {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  position: absolute;
  bottom: -20px;
  left: 0;
  overflow-y: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .chip {
    border-radius: 25px;
    background-color: var(--primary);
    opacity: 0.8;
    text-align: center;
    padding: 0.2rem 0.7rem;
    font-size: 0.6rem;
    color: var(--white);
    cursor: pointer;
    text-wrap: nowrap;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.was-validated .input-group>.form-control:not(:focus):invalid {
  z-index: 0 !important;
}

.modal-date {
  .react-datepicker-wrapper input {
    min-width: auto !important;
  }
}

.btngrp {
  gap: 1.5rem;
  margin-top: 1rem;

  .btn {
    padding: 0 !important;
    color: var(--black);
    font-weight: 400;
    font-size: var(--p) !important;
    border-radius: 0 !important;
    padding-bottom: 0.5rem !important;
  }
}

.react-date-picker__wrapper {
  border: none !important;
}

.input-group-text {
  padding: .375rem 0;
}


.excel-table {
  table {
    thead {
      th {
        background-color: white !important;
        color: var(--light-text) !important;
        padding: 0.5rem !important;
        border-left-width: 1px !important;
      }
    }

    .mb-3 {
      margin-bottom: 0 !important;
    }

    .input-group-text,
    input {
      border-bottom: 0 !important;
    }

    tbody {
      tr {
        td:first-child {
          text-align: left;
          padding-left: 0.5rem !important;
          height: 30px;
        }

        input {
          &:focus-visible {
            outline: none;
          }
        }
      }
    }

  }
}

.input-group>.form-control:not(:focus).is-valid,
.input-group>.form-floating:not(:focus-within).is-valid,
.input-group>.form-select:not(:focus).is-valid,
.was-validated .input-group>.form-control:not(:focus):valid,
.was-validated .input-group>.form-floating:not(:focus-within):valid,
.was-validated .input-group>.form-select:not(:focus):valid {
  z-index: 0;
}

.editable-date {
  .date-picker {
    min-width: 135px;
  }

  .mb-3 {
    margin-bottom: 0 !important;
  }

  .input-group-text {
    border: 0;
  }

  .react-datepicker__input-container {
    input {
      border: none !important;
    }
  }
}